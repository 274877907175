import { render, staticRenderFns } from "./PermissionOption.vue?vue&type=template&id=48cfe508&scoped=true"
import script from "./PermissionOption.vue?vue&type=script&lang=js"
export * from "./PermissionOption.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48cfe508",
  null
  
)

export default component.exports